import { json, type DataFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { type RoleType } from 'types/enums.ts'
import { type DrawerItem } from '~/components/layout/drawer-navigator.tsx'
import { MainContainer } from '~/components/layout/main-container.tsx'
import { MainLayout } from '~/components/layout/main-layout.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { getUserRole, requireUserId } from '~/features/auth/auth.server.ts'

export const meta: MetaFunction = () => [{ title: 'Miistico' }]

export async function loader({ request }: DataFunctionArgs) {
  await requireUserId(request, 'user')
  const userRole = await getUserRole(request)
  return json({ userRole })
}

export const userDrawerItems: DrawerItem[] = [
  {
    title: 'Inicio',
    icon: <Icon name="home" size="drawer" />,
    href: '/',
    isExactPath: true,
  },
  {
    title: 'Mi perfil',
    icon: <Icon name="user" size="drawer" />,
    href: '/user/profile',
  },
  {
    title: 'Proximas sesiones',
    icon: <Icon name="history" size="drawer" />,
    href: '/user/next-sessions',
  },
  {
    title: 'Mis compras',
    icon: <Icon name="shopping-cart" size="drawer" />,
    href: '/user/purchases',
  },
  {
    title: 'Favoritos',
    icon: <Icon name="bookmark" size="drawer" />,
    href: '/user/favorites',
  },
]

export default function Index() {
  const { userRole } = useLoaderData<typeof loader>()
  return (
    <MainLayout userRole={userRole as RoleType}>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </MainLayout>
  )
}
