import { json, type DataFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { type RoleType } from 'types/enums.ts'
import { type DrawerItem } from '~/components/layout/drawer-navigator.tsx'
import { MainContainer } from '~/components/layout/main-container.tsx'
import { MainLayout } from '~/components/layout/main-layout.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { getUserRole, requireUserId } from '~/features/auth/auth.server.ts'
export const meta: MetaFunction = () => [{ title: 'Miistico' }]

export async function loader({ request }: DataFunctionArgs) {
  await requireUserId(request, 'admin')
  const userRole = await getUserRole(request)
  return json({ userRole })
}

export const adminDrawerItems: DrawerItem[] = [
  {
    title: 'Inicio',
    icon: <Icon name="home" size="drawer" />,
    href: '/admin/',
    isExactPath: true,
  },
  {
    title: 'Usuarios',
    icon: <Icon name="user" size="drawer" />,
    href: '/admin/users',
  },
  {
    title: 'Profesionales',
    icon: <Icon name="professional" size="drawer" />,
    href: '/admin/professionals',
  },
  {
    title: 'Servicios',
    icon: <Icon name="service" size="drawer" />,
    href: '/admin/services',
  },
  {
    title: 'Valoraciones',
    icon: <Icon name="message-square" size="drawer" />,
    href: '/admin/reviews',
  },
  {
    title: 'Calendario',
    icon: <Icon name="calendar" size="drawer" />,
    href: '/admin/calendar',
  },
  {
    title: 'Cupones',
    icon: <Icon name="cupon" size="drawer" />,
    href: '/admin/cupons',
  },
  {
    title: 'Categorias',
    icon: <Icon name="category" size="drawer" />,
    // href: '/admin/categories',
    // New default category: to fetch categories data
    href: '/admin/categories/category',
  },
  {
    title: 'Configuracion',
    icon: <Icon name="config" size="drawer" />,
    href: '/admin/config',
  },
]

export default function Index() {
  const { userRole } = useLoaderData<typeof loader>()
  return (
    <MainLayout userRole={userRole as RoleType}>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </MainLayout>
  )
}
